import "./sass/7-bundles/main.scss";
import cursor from "./scripts/cursor.js";

async function loadExperience() {
  import("./experience/experience.js");
}

async function loadSmooth() {
  import("./scripts/smooth.js");
}

async function loadServicesAnimation() {
  import("./scripts/servicesAnimation.js");
}

async function loadForm() {
  import("./scripts/form.js");
}

async function loadSlider() {
  import("./scripts/slider.js");
}

window.addEventListener("load", async function loadModules() {
  // await loadSmooth();
  await loadExperience();
  await loadServicesAnimation();
  await loadForm();
  await loadSlider();
});
