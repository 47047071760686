const cursorOut = document.querySelector('.cursor')

var mouseX=window.innerWidth/2,
    mouseY=window.innerHeight/2;

var cursor = { 
  el: cursorOut,
  x:window.innerWidth/2, 
  y:window.innerHeight/2, 
  w:80, 
  h:80, 
  update:function(){
    l = this.x-this.w/2; 
    t = this.y-this.h/2;
    this.el.style.transform = `translate3D(${l}px, ${t-58}px, 0)`
            }
}

window.addEventListener('mousemove', (e) => {
  mouseX = e.clientX;
  mouseY = e.clientY;
})

setInterval (move,1000/60)
function move(){
  cursor.x = lerp (cursor.x, mouseX, 0.1);
  cursor.y = lerp (cursor.y, mouseY, 0.1);
  cursor.update() 
}

function lerp (start, end, amt){
  return (1-amt)*start+amt*end
}